<template>
  <div>
    <section>
      <div class="col justify-content-center">
        <h1 class="text-center">Find your sample ballot</h1>
        <!-- eslint-disable-next-line max-len -->
        <p class="text-center">
          Please enter your zip code and house number to get started.
        </p>
      </div>
      <div class="row justify-content-center">
        <form id="form" class="form-inline" v-on:submit.prevent="submit">
          <div class="form-row align-items-center">
            <div class="form-group m-3">
              <label for="zipCodeInput" class="m-1">Zip Code</label>
              <!-- eslint-disable-next-line max-len -->
              <input
                id="zipCodeInput"
                v-model.lazy.number="zipCode"
                type="number"
                min="0"
                v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validZip(zipCode) && zipBlurred,
                }"
                v-on:blur="zipBlurred = true"
                required
              />
              <div class="invalid-feedback">
                Zip Code is required.
              </div>
            </div>
            <div class="form-group m-3">
              <label for="houseNumberInput" class="m-1">House Number</label>
              <!-- eslint-disable-next-line max-len -->
              <input
                id="houseNumberInput"
                v-model.lazy.number="houseNumber"
                type="number"
                min="0"
                v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validHouse(houseNumber) && houseBlurred,
                }"
                v-on:blur="houseBlurred = true"
                required
              />
              <div class="invalid-feedback">
                House number is required.
              </div>
            </div>
            <!-- eslint-disable-next-line max-len -->
            <div class="form-group col-12 col-sm-1">
              <input
                id="submit"
                type="submit"
                class="btn btn-primary ml-2"
                value="Next"
              />
            </div>
          </div>
        </form>
      </div>
      <div v-if="loading" class="row justify-content-center">
        <div class="col-auto alert alert-secondary">
          <h2>Loading...</h2>
        </div>
      </div>
      <div v-if="error" class="row justify-content-center">
        <div class=" alert alert-warning col-sm-6" role="alert">
          <h2>An error has occured, please refresh the page and try again.</h2>
        </div>
      </div>
      <div v-if="streetNames.length > 0" class="row justify-content-center">
        <div class="col-auto form-group form-inline">
          <label for="streetDropDown" class="m-1 text-center"
            >Select your street</label
          >
          <select
            v-model="selected"
            id="streetDropDown"
            class="m-2 form-control"
          >
            <option disabled value="">Please select one</option>
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <option v-for="name in streetNames" v-bind:value="name">
              {{ name.StreetAddr }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="selected" class="row justify-content-center mt-3">
        <a v-bind:href="ballotLink" class="btn btn-primary btn-lg"
          >View my sample ballot</a
        >
      </div>
      <div v-if="badAddress" class="row justify-content-center">
        <div class="col">
          <h2 class="text-center">
            A precinct could not be found for that address.
          </h2>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Precinct',
  data: function data() {
    return {
      houseNumber: '',
      zipCode: '',
      badAddress: false,
      streetNames: [],
      selected: '',
      loading: false,
      error: false,
      zipBlurred: false,
      houseBlurred: false,
      valid: false,
    };
  },
  computed: {
    ballotLink() {
      return `./ballot/${this.selected.PctCode}/${this.selected.SchDist}`;
    },
  },
  methods: {
    validate() {
      this.zipBlurred = true;
      this.houseBlurred = true;
      if (this.validZip(this.zipCode) && this.validHouse(this.houseNumber)) {
        this.valid = true;
      }
    },
    validZip(zipCode) {
      const re = /(\d{5})/;
      return re.test(zipCode);
    },
    validHouse(houseNumber) {
      const re = /(\d+)/;
      return re.test(houseNumber);
    },
    submit: function submit() {
      this.loading = true;
      this.badAddress = false;
      this.selected = '';
      this.streetNames = [];
      this.validate();
      const oldThis = this;
      // const baseUrl = 'https://api.dflcloud.org/v1/precinctfinder?';
      const baseUrl =
        'https://ktkcvlh44j.execute-api.us-east-1.amazonaws.com/precincts?';
      const zip = `zip=${this.zipCode}`;
      const houseNum = `houseNum=${this.houseNumber}`;

      if (this.valid) {
        // eslint-disable-next-line no-undef
        axios
          .get(`${baseUrl + zip}&${houseNum}`)
          .then((response) => {
            oldThis.loading = false;
            oldThis.error = false;
            response.data.forEach((item) => {
              oldThis.streetNames.push(item);
            });
            if (oldThis.streetNames.length === 0) {
              oldThis.badAddress = true;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            oldThis.loading = false;
            oldThis.error = true;
          });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
