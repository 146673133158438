<template>
    <div>
        <hr>
        <section>
            <div class="row">
                <div class="col-sm">
                    <h2 class="text-center mb-4">
                      Election Day is Tuesday, November 2<sup>nd</sup></h2>
                </div>
                </div>
        <div class="row justify-content-start mb-3">
                 <div class="col">
                <h3 class="text-center">Will you be a voter?</h3>
                <p class="m-4 text-center">
Make Your Voting Plan Today!<br/>
Vote Early by Mail<br/>
Vote Early at Minneapolis Elections & Voter Services<br/>
Vote in-person on Election Day</p>
                <h3 class="text-center">Learn more at <a href="https://minneapolisdfl.org" target="_blank" rel="noopener">minneapolisdfl.org</a></h3>
            </div>

        </div>
        </section>
    </div>
</template>
<script>
export default {
  name: 'Values',
  data: function data() {
    return {
      values: [
        'A Living Wage for All',
        'Quality Public Schools Across MN',
        'Affordable Health Care',
        'Renewable Energy',
        'Protect Social Security & Medicare',
        'Take Care of Veterans',
      ],
    };
  },
};
</script>
