<template>
  <div class="container">
    <h1 v-if="!error" class="text-center">{{ greeting }}</h1>
    <div class="row">
      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <div class="col justify-content-around m-2" v-for="candidate in selected">
        <div class="row">
          <div class="col-12">
            <!-- eslint-disable-next-line max-len -->
            <img
              v-if="candidate.image"
              class=" photo rounded mx-auto d-block"
              v-bind:alt="photoCaption(candidate)"
              :src="candidate.image"
              @error="candidate.image = '../../avatar.png'"
            />
            <div v-else class="mx-auto d-block">
              <!-- eslint-disable-next-line max-len -->
              <font-awesome-icon
                icon="user-circle"
                class="fa-10x mx-auto d-block text-secondary"
              />
            </div>
          </div>
          <div class="text-center align-self-end col-12">
            <span v-if="candidate.website">
              <!-- eslint-disable-next-line max-len -->
              <a
                v-bind:href="candidate.website"
                target="_blank"
                rel="noopener"
                >{{ candidate.name }}</a
              >
            </span>
            <span v-else>
              {{ candidate.name }}
            </span>
            <br />
            {{ candidate.office }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Candidates',
  props: {
    district: String,
    precinct: String,
    school: String,
  },
  data: function data() {
    return {
      selected: [],
      error: false,
    };
  },
  created() {
    this.getCandidates();
  },
  computed: {
    greeting() {
      if (this.validDistrict() && !this.error) {
        if (this.district.length === 3) {
          return `Your Democratic Team for House District ${this.district.toUpperCase()}`;
        }
        return `Your Democratic Team for Senate District ${this.district}`;
      }
      return 'Your Democratic Team';
    },
  },
  methods: {
    validDistrict() {
      const pattern = new RegExp(/\d{2}[aAbB]?$/);
      return pattern.test(this.district);
    },
    getCandidates() {
      const oldThis = this;
      const url = `https://ktkcvlh44j.execute-api.us-east-1.amazonaws.com/candidates?precinct=${this.precinct}`;

      // eslint-disable-next-line no-undef
      axios
        .get(`${url}`)
        .then((response) => {
          response.data.forEach((item) => {
            oldThis.selected.push(item);
          });
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          oldThis.error = true;
        });
    },
    photoCaption(candidate) {
      let caption = 'DFL Candidate ';
      caption += candidate.name;
      return caption;
    },
  },
};
</script>
<style lang="scss">
.photo {
  max-width: 200px;
  max-height: 200px;
}
</style>
