import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import District from './views/District.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
    },
    {
      path: '/sd/:district(\\d{2})',
      component: District,
      props: true,
    },
    {
      path: '/ld/:district(\\d{2}[aAbB])',
      component: District,
      props: true,
    },
    {
      path: '/ballot/:precinct(\\d{9})/:school(\\d+-\\d+)',
      component: District,
      props: true,
    },
    {
      path: '/*',
      redirect: '/',
    },
  ],
});
