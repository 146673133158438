<template>
  <div>
    <header>
  <div class="row justify-content-center m-4">
    <div class="col col-sm-auto">
      <h1 class="logo">Minneapolis DFL</h1>
    </div>
  </div>
  </header>
  <main role="main">
    <Precicnt />
    <Values />
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Values from '@/components/Values.vue';
import Precicnt from '@/components/Precinct.vue';

export default {
  name: 'home',
  components: {
    Values,
    Precicnt,
  },
  props: {
    district: String,
  },
};
</script>
<style lang="scss">
.logo {
  font-weight: 900;
}
a {
  color: #e62517;
}
main, header {
  color: #1a3a5c;
}

</style>

